import React from 'react';


const PageTitle = () => {
    return (
        <section id="page-title" className="block bg-white " >

            <div id="text" className="block spacer">
                <div className="wrapper">
                    <h1 className="large">Built for any road ahead.</h1>
                    <h4>We are developing the next generation of self-driving technology</h4>
                </div>
            </div>

            <div id="img" className="block ">
                <div className="img-size-1">
                    <div className="img object-fit">
                        <div className="object-fit-cover">
                            <img src="assets/img/pages/home/home-top-image.jpg" alt="Tools" />
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    );
};

export default PageTitle;
