import React, { Component } from "react";
import axios from "axios";

class ContactsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        company_name: "",
        message: "",
        _captcha: "false",
      },
      successMessage: "Message was sent successfully",
      warningMessage: "Fill in the form please!",
      errorMessage: "Something went wrong. Try again later!",
      alertClass: "",
      responseMessage: "",
      alertTimeout: "",
      delay: 5000,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();

    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }

    this.setState({ isSubmitting: true });

    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios
      .post("https://formsubmit.co/info@saivvy.com", this.state.values)
      .then((response) => {
        this.setState({ responseMessage: this.state.successMessage });
        this.callAlert(this.state.responseMessage, "success");
      })
      .catch((error) => {
        this.callAlert(this.state.errorMessage, "error");
      });
  };

  removeAlert = () => {
    clearTimeout(this.state.alertTimeout);

    this.setState({
      alertTimeout: setTimeout(function () {
        var element = document.querySelector("#alert");

        element.classList.remove("fadeIn");
        element.classList.add("fadeOut");

        setTimeout(function () {
          element.remove();
        }, 900);
      }, this.state.delay),
    });
  };

  callAlert = (message, type) => {
    console.log(type);
    if (!document.querySelector("#alert")) {
      if (type === "success") {
        this.setState({ alertClass: "success" });
      }

      if (type === "error") {
        this.setState({ alertClass: "danger" });
      }

      if (type === "warning") {
        this.setState({ alertClass: "warning" });
      }

      var alert =
        '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
        this.state.alertClass +
        '">' +
        message +
        "</div>";

      var element = document.querySelector("#cf-1");

      element.insertAdjacentHTML("beforeend", alert);

      this.removeAlert();
    }
  };

  handleInputChange = (e) =>
    this.setState({
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value,
      },
    });

  render() {
    return (
      <form onSubmit={this.submitForm} id="cf-1" className="contact-form">
        {/* Email */}
        <div className="form-group">
          <label htmlFor="cf-1-phone" className="form-label-lg before">
            Email *
          </label>
          <input
            value={this.state.values.email}
            onChange={this.handleInputChange}
            name="email"
            type="email"
            id="cf-1-email"
            required="required"
            className="form-control font-size-lg"
          />
        </div>

        {/* First name and Last name */}
        <div className="form-group">
          <p className="input-group gutter-width-md no-space">
            <span className="gutter-width">
              <label htmlFor="cf-1-name" className="form-label-lg before">
                First Name *
              </label>
              <input
                value={this.state.values.first_name}
                onChange={this.handleInputChange}
                name="first_name"
                type="text"
                id="cf-1-first_name"
                required="required"
                className="form-control font-size-lg"
              />
            </span>

            <span className="gutter-width">
              <label htmlFor="cf-1-email" className="form-label-lg before">
                Last Name *
              </label>
              <input
                value={this.state.values.last_name}
                onChange={this.handleInputChange}
                name="last_name"
                type="text"
                id="cf-1-last_name"
                required="required"
                className="form-control font-size-lg"
              />
            </span>
          </p>
        </div>

        {/* Phone number and company */}
        <div className="form-group">
          <p className="input-group gutter-width-md no-space">
            <span className="gutter-width">
              <label htmlFor="cf-1-phone" className="form-label-lg before">
                Phone Number *
              </label>
              <input
                value={this.state.values.phone}
                onChange={this.handleInputChange}
                name="phone"
                type="text"
                id="cf-1-phone"
                required="required"
                className="form-control font-size-lg"
              />
            </span>

            <span className="gutter-width">
              <label htmlFor="cf-1-email" className="form-label-lg before">
                Company Name *
              </label>
              <input
                value={this.state.values.company_name}
                onChange={this.handleInputChange}
                name="company_name"
                type="text"
                id="cf-1-last_name"
                required="required"
                className="form-control font-size-lg"
              />
            </span>
          </p>
        </div>

        {/* Message */}
        <div className="form-group">
          <label htmlFor="cf-1-message" className="form-label-lg before">
            Message *
          </label>
          <textarea
            value={this.state.values.message}
            onChange={this.handleInputChange}
            name="message"
            id="cf-1-message"
            required="required"
            className="form-control font-size-lg"
          ></textarea>
        </div>

        <div className="form-group mb-0">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default ContactsForm;
