import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/home/PageTitle';
import Projects from '../blocks/home/Projects';
import Facts from '../blocks/home/Facts';
import Subscribe from '../blocks/home/Subscribe';
// import Services from '../blocks/home/Services';
import ApplicationsSwiper from '../blocks/home/ApplicationsSwiper';
// import FAQ from '../blocks/home/FAQ';

{/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"></link> */}

const Home = () => {
    useEffect(() => {
        document.body.classList.add('home');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('home');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    },[]);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Home | Saivvy - Off-Road Autonomous Driving</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Header />

            <main id="main" className="site-main">
                
                <div className="content">
                    
                    <div className="clearfix">
                        
                        <PageTitle />

                        
                        
                        <Projects />

                        {/* <div className="home-pre-bottom">
                            <div className="wrapper">

                                <h4>The Saivvy team makes your transition to autonmous driving seamless, 
                                    with a software that empower your business to be 
                                    more productive and sustainable.</h4>
                                
                            </div>
                        </div> */}

    

                        <ApplicationsSwiper />

           

                        <div className="home-pre-bottom">

                            <div id="text" className="block spacer">
                                <div className="wrapper">
                                <h2>We approach problems with a multidisciplinary approach.</h2>
                                <h4>The importance of creating a collaborative culture is essential for achieving our goals. 
                                    The complexity of our products require a diverse range of skills and 
                                    perspectives to come together and make crucial decisions on a daily basis.</h4>
                                    
                                </div>
                            </div>

                        </div>

                        {/* <FAQ /> */}

                        <Facts />




                        

                        {/* <News /> */}
                        
                    </div>
                </div>

                
            </main>



            <Subscribe />

            <Footer />
            
        </Fragment>
    );
};

export default Home;
