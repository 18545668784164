import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Subscribe from '../blocks/home/Subscribe';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/our-company/PageTitle';
import AboutUs from '../blocks/home/AboutUs';
import Partners from '../blocks/home/TechnologyApplications';
import Applications from '../blocks/home/Applications';
import Contacts from '../blocks/home/Contacts';

const OurCompany = () => {
    useEffect(() => {
        document.body.classList.add('page');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('page');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    },[]);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Our Company | Saivvy - Off-Road Autonomous Driving</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Header />

            <main id="main" className="site-main">
                {/* <PageTitle /> */}

                <div id="page-content" className="spacer">
                    <div className="content">
                        
                        
                        <AboutUs />


                    </div>
                </div>
            </main>

            <Subscribe />
            <Footer />
            
        </Fragment>
    );
};

export default OurCompany;
