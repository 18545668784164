import React from 'react';

import LoadMore from '../../components/loadmore/LoadMore';

import NewsData from '../../data/news/newsData.json';

const NewsBlog = () => {
    return (
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
            <div className="row gutter-width-md with-pb-lg">
                { NewsData && NewsData.map( (item, key) => {
                    return (
                        <div key={key} className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="card card-post">
                                <div className="card-top position-relative">
                                    <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>
                                        <div className="img object-fit overflow-hidden">
                                            <div className="object-fit-cover transform-scale-h">
                                                <img className="card-top-img" src={ item.imgSrc } alt={ item.title } />
                                            </div>
                                        </div>
                                    </a>
    
                                    <div className="card-category">
                                        <a title={ item.category } href={ process.env.PUBLIC_URL + item.categoryLink} className="btn btn-secondary btn-sm">{ item.category }</a>
                                    </div>
                                </div>
    
                                <div className="card-body">
                                    <h4 className="card-title">
                                        <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>{ item.title }</a>
                                    </h4>
    
                                    <p className="card-text">{ item.description }</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <LoadMore />
        </div>
    );
};

export default NewsBlog;
