import React from 'react';

const Subscribe = () => {
    return (
        <section id="subscribe" className="subscribe">
            <div className="wrapper">
                <div class="row">
                    <div class="column">
                        <div class="first-column">
                            <h4>Interested in what we are doing?</h4>
                        </div>
                    </div>



                    <div class="column">
                        <div class="second-column">
                            <a href={ process.env.PUBLIC_URL + '/contacts' }> 
                                                            <h4>Get in touch!</h4>
                                                            </a>
                                                            
                        </div>
                    </div>

                    <div class="third-column">
                        <a className="btn btn-link p-0 border-0 min-w-auto" href={ process.env.PUBLIC_URL + '/contacts' }>
                            <i className="forch-icon-arrow-right body_links_hover_color"></i>
                        </a>
                    </div>

                </div>                                    
            </div>
            
        </section>
    );
};

export default Subscribe;
