import React from 'react';

import SearchFormPrimary from '../../components/form/SearchFormPrimary';
import Categories from '../sidebar/Categories';
import RecentEntries from '../sidebar/RecentEntries';
import RecentComments from '../sidebar/RecentComments';
import Tags from '../sidebar/Tags';

const Sidebar = () => {
    return (
        <aside id="aside" className="widget-area">
            <div className="widget widget_search">
                <SearchFormPrimary />
            </div>

            <Categories />

            <RecentEntries />

            <RecentComments />

            <Tags />
        </aside>
    );
};

export default Sidebar;
