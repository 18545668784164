import React from 'react';

import projects_mid_video from '../../assets/img/video/projects-mid.mp4';

const Projects = () => {
    return (
        <div id="projects" className="projects">

            <div className="projects-top">
                <div className="wrapper">
                    <h1>An autonomous driving software built for whatever you call a road.</h1>
                        
                </div>
            </div>

            <div className="projects-video">
            
                <video className='videoTag' autoPlay loop muted>
                    <source src={projects_mid_video} type='video/mp4' />
                    
                </video>

            </div>  
        </div>
    );
};

export default Projects;
