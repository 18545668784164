import React from 'react';
import sample from '../../assets/img/video/about-us-contact-us.mp4';


const AboutUs = () => {
    return (
        <div id="about-us" className="block">

            <div className="about-top">
                <div id="img" className="block spacer">
                    <div className="wrapper">
                    <h2 className="large">Off-Road Magic.</h2>
                        <h4>With more than 130 billion square meters of off-road land and millions of off-road vehicles, 
                            it's surprising that there are still no safe and reliable solutions for off-road autonomous driving. 
                            That's where Saivvy comes in. Our mission is to empower businesses of all sizes with a secure and reliable 
                            off-road autonomous solution. We're dedicated to making off-road driving safer and more efficient with our 
                            cutting-edge technology. Join us on our journey to revolutionize the off-road industry.</h4>
                            
                    </div>
                    {/* <div className="about-us-image-wrapper1">
                        <div className="row gutter-width-md with-pb-lg">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="img-size-2">
                                    <div className="img object-fit head-computer-vision">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/pages/about-us/about-top-image-1.jpg" alt="Industrial equipment" />

                                            <div className="head-computer-vision-img-caption-name">
                                                <h5>Serwan Jassim</h5>
                                            </div>
                                            <div className="head-computer-vision-img-caption-role">
                                                <p>Head of Computer Vision</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="img-size-2">
                                    <div className="img object-fit">
                                        <div className="object-fit-cover head-engineering">
                                            <img src="assets/img/pages/about-us/about-top-image-2.jpg" alt="Industrial equipment" />

                                            <div className="head-engineering-img-caption-name">
                                                <h5>Sybren Geel</h5>
                                            </div>
                                            <div className="head-engineering-img-caption-role">
                                                <p>Head of Engineering</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>  
            </div>

            <div className="about-under-top">

                <div id="text" className="block spacer p-top-xs">
                    <div className="wrapper ceo-quote">
                    <h2>"Saivvy is a young company but it has already made a big impact in the tech industry. 
                        With its innovative approach to problem-solving and customer-focused mindset, Saivvy plans to 
                        quickly become a leading player in the off-road autonomus market. The company's commitment to excellence and 
                        collaboration has allowed it to consistently deliver high-quality Software, 
                        earning the trust of our early adopters."</h2>
                        
                    </div>
                </div>


                {/* <div className="ceo-img">
                    <img src="assets/img/pages/about-us/ceo-image.png" alt="Saivvy's CEO Elia Bruni" />

                    <div className="ceo-img-caption-name">
                        <h4>Elia Bruni</h4>
                    </div>
                    <div className="ceo-img-caption-role">
                        <p>Founder and CEO</p>
                    </div>
                    

                    
                </div> */}
                            

                

            </div>

            <div className="about-middle">
                <article>
                <div className="re-order">
                    <div class="box col-50 text">
                        <div className='wrapper'>
                            <h3>How we solve autonomy</h3>
                            <h4>Our company is renowned for our expertise in designing, training, and testing the latest generation 
                                                of artificial intelligence (AI) approaches. We have a team of highly skilled and experienced AI 
                                                engineers who are dedicated to pushing the boundaries of what is possible with this technology. 
                                                We are constantly experimenting with new algorithms and architectures to find the most effective 
                                                solutions to the challenges our clients face. Our rigorous testing process ensures that our AI models 
                                                are able to perform at the highest levels, providing reliable and accurate results. Our commitment to 
                                                innovation and excellence has helped us to become leaders in the field of AI, and we are constantly 
                                                striving to improve and advance this technology.</h4>
                        </div>
                    </div>
                    <div class="box col-50 image">
                        <img src="assets/img/pages/about-us/how-we-solve-autonomy.jpg" />
                    </div>
                    </div>
                </article>
                <article>
                    <div className="re-order">
                        <div class="box col-50 text">
                            <div className='wrapper'>
                                <h3>How we code</h3>
                                <h4>At our company, we pride ourselves on our ability to write high-quality industrialized software. 
                                                            We have a strong focus on continuous integration and ensure that all of our code is thoroughly tested. 
                                                            This allows us to quickly and efficiently identify and fix any potential issues, ensuring that our software 
                                                            is of the highest quality. We hold ourselves to the highest standards and are committed to delivering the best 
                                                            possible products to our customers.</h4>
                            </div>
                        </div>
                        <div class="box col-50 image">
                            <img src="assets/img/pages/about-us/how-we-code.jpg"/>
                        </div>
                    </div>
                </article>
            </div>

            <div className="about-pre-bottom">
                <div className="wrapper">                  
                    <div className="row gutter-width-lg">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="title">
                                <h2>Revolutionizing Off-Road Work: Saivvy's Autonomous Software Transforming Industries and Improving Lives.</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row gutter-width-lg with-pb-lg spacer p-top-lg justify-content-end">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="description max-width-2 text-secondary">
                            <h4>Saivvy is at the forefront of developing technology that will have a significant impact on society. We are focused 
                                on revolutionizing the way work is done off-road with our autonomous software. Our software is designed to improve 
                                efficiency and safety while reducing the need for human labor in difficult and dangerous environments. By automating 
                                tasks such as mining, construction, and agriculture, our technology has the potential to improve the lives of workers 
                                and increase productivity in industries that rely heavily on off-road work. Additionally, our technology is built 
                                with safety and sustainability in mind, which helps to protect the environment while also reducing the risk of accidents. 
                                We are excited about the positive impact our technology will have on society and look forward to continuing to push the 
                                boundaries of what is possible with autonomous systems.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper">                  
                    <div className="row gutter-width-lg">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="title">
                                <h2>Saivvy's Commitment to Openness, Gender Balance, and Diversity.</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row gutter-width-lg with-pb-lg spacer p-top-lg justify-content-end">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="description max-width-2 text-secondary">
                                <h4>At Saivvy, we believe in fostering an open, fair and gender-balanced workplace. We strive to create 
                                    a culture where all voices are heard and everyone is treated with respect and fairness. This is not only 
                                    the right thing to do, but it also leads to better decision making and a more innovative and productive 
                                    team. We believe that diversity and inclusion are essential for our continued success and we make it a 
                                    priority to ensure that our team reflects the diversity of the communities we serve. We also actively 
                                    promote gender balance throughout all levels of the company. We are committed to creating a workplace 
                                    where everyone can thrive and reach their full potential.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="about-bottom">

            <div id="text" className="block spacer">
                <div className="wrapper">
                <h2>We approach problems with a multidisciplinary approach.</h2>
                <h4>The importance of creating a collaborative culture is essential for achieving our goals. 
                    The complexity of our products require a diverse range of skills and 
                    perspectives to come together and make crucial decisions on a daily basis.</h4>
                    
                </div>
            </div>

            </div>

            <div className="careers-video">
                <div className="video-text">                        
                    <div id="text" className="block spacer">
                        <div className="wrapper">
                            <h2>We're looking for people who are willing to take risks and think outside the box. 
                                If this sounds like you, don't hesitate to reach out to us.</h2>
                                 <a className="btn btn-primary" href={ process.env.PUBLIC_URL + '/contacts' }>Contact us</a>
                            
                        </div>
                    </div>
                 </div>
            
                <video className='videoTag' autoPlay loop muted>
                    <source src={sample} type='video/mp4' />
                    
                </video>
 
            </div>  
            
        </div>
            
                        
    );
};

export default AboutUs;