import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Technology from './pages/Technology';
import Contacts from './pages/Contacts';
import News from './pages/News';
import NewsSinglePost from './pages/NewsSinglePost';
import ProjectInside from './pages/ProjectInside';
import Projects from './pages/Projects';
import Applications from './pages/Applications';
import ApplicationInside from './pages/ApplicationInside';
import page404 from './pages/404';
import UserInterface from './pages/UserInterface';
import OurCompany from './pages/OurCompany';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={Home} />
        <Route exact path={`${process.env.PUBLIC_URL + '/technology'}`} component={Technology} />
        <Route exact path={`${process.env.PUBLIC_URL + '/our-company'}`} component={OurCompany} />
        <Route exact path={`${process.env.PUBLIC_URL + '/contacts'}`} component={Contacts} />
        <Route exact path={`${process.env.PUBLIC_URL + '/news'}`} component={News} />
        <Route exact path={`${process.env.PUBLIC_URL + '/news-single-post'}`} component={NewsSinglePost} />
        <Route exact path={`${process.env.PUBLIC_URL + '/project-inside'}`} component={ProjectInside} />
        <Route exact path={`${process.env.PUBLIC_URL + '/projects'}`} component={Projects} />
        <Route exact path={`${process.env.PUBLIC_URL + '/application-inside'}`} component={ApplicationInside} />
        <Route exact path={`${process.env.PUBLIC_URL + '/applications'}`} component={Applications} />
        <Route exact path={`${process.env.PUBLIC_URL + '/ui'}`} component={UserInterface} />
        <Route exact component={page404} />
      </Switch>
    </Router>
  );
}

export default App;
