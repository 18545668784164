import React from 'react';
import { withRouter } from 'react-router-dom';

const ModalMenuPrimary = ( props ) => {
    return (
        <nav className="menu-primary">
            <ul className="nav">
                <li className={"nav-item " + ( props.location.pathname ===  window.location.pathname && props.location.pathname === process.env.PUBLIC_URL + '/'  ? ' current-nav-item' : '')}>
                    <a title="Home" href={process.env.PUBLIC_URL + '/'}>Home</a>
                </li>

                <li className={'nav-item' + (window.location.pathname.includes( '/technology' ) ? ' current-nav-item' : '')}>
                    <a title="Technology" href={process.env.PUBLIC_URL + '/technology'}>Technology</a>
                </li>

                {/* <li className={'nav-item' + (window.location.pathname.includes( '/applications' ) || window.location.pathname.includes( "/service-inside" ) ? ' current-nav-item' : '')}>
                    <a title="Applications" href={process.env.PUBLIC_URL + '/applications'}>Applications</a>
                </li> */}

                <li className={'nav-item' + (window.location.pathname.includes( '/our-company' ) ? ' current-nav-item' : '')}>
                    <a title="Our company" href={process.env.PUBLIC_URL + '/our-company'}>Our company</a>
                </li>

                {/* <li className={'nav-item' + (window.location.pathname.includes( '/projects' ) || window.location.pathname.includes( "/project-inside" ) ? ' current-nav-item' : '')}>
                    <a title="Projects" href={process.env.PUBLIC_URL + '/projects'}>Projects</a>
                </li> */}

                {/* <li className={'nav-item' + (window.location.pathname.includes( '/news' ) || window.location.pathname.includes( "/news-single-post" ) ? ' current-nav-item' : '')}>
                    <a title="News" href={process.env.PUBLIC_URL + '/news'}>News</a>
                </li> */}

                <li className={'nav-item' + (window.location.pathname.includes( '/contacts' ) ? ' current-nav-item' : '')}>
                    <a title="Contacts" href={process.env.PUBLIC_URL + '/contacts'}>Contacts</a>
                </li>
            </ul>
        </nav>
    );
};

export default withRouter(ModalMenuPrimary);
