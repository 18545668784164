import React from 'react';

import TagsData from '../../data/news-single-post/tagsData.json';

const Tags = () => {
    return (
        <div className="tags">
            <p>
                { TagsData && TagsData.map( (item, key) => {
                    return (
                        <a key={key} title={ item.title } href={ process.env.PUBLIC_URL + item.link }>{ item.title }</a>
                    )
                })}
            </p>
        </div>
    );
};

export default Tags;
