import React from 'react';

import RecentData from '../../data/sidebar/recentCommentsData.json';

const RecentComments = () => {
    return (
        <div className="widget widget_recent_comments">
            <h5 className="widget-title">Recent Comments</h5>

            <ul id="recentcomments">
                { RecentData && RecentData.map( (item, key) => {
                    return (
                        <li key={key} className="recentcomments">
                            <span className="comment-author-link">{ item.author }</span> on
                            <a title={ item.author } href={ process.env.PUBLIC_URL + item.link}>{ item.title }</a>
                        </li> 
                    )
                })}
            </ul>
        </div>
    );
};

export default RecentComments;
