import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/404/PageTitle';
import BackToHome from '../components/button/BackToHome';
import SearchFormPrimary from '../components/form/SearchFormPrimary';

const Page404 = () => {
    useEffect(() => {
        document.body.classList.add('error404');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('error404');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    },[]);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>404 | Saivvy - Off-Road Autonmous Driving</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <div id="page-content">
                    <div className="wrapper">
                        <div className="content">
                            <div className="row gutter-width-md">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <PageTitle />

                                    <p className="text after">The page you were looking for couldn't be found. The page could be removed or you misspelled the word while searching for it. Maybe try a search?</p>

                                    <div className="form">
                                        <SearchFormPrimary />
                                    </div>

                                    <BackToHome />
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className="img-404" style={{ backgroundImage: 'url("assets/img/placeholder/19_img.jpg")' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
            
        </Fragment>
    );
};

export default Page404;
