import React from "react";

const Footer = () => {
  const path = window.location.pathname;

  return (
    <footer id="footer" className="site-footer">
      <div className="wrapper">
        {/* Here is the footer menu     */}
        <div className="footer-menu">
          <ul className="nav">
            <li
              className={
                "nav-item" +
                (window.location.pathname.includes("/technology")
                  ? " current-nav-item"
                  : "")
              }
            >
              <a title="Home" href={process.env.PUBLIC_URL + "/"}>
                Home
              </a>
            </li>

            <li
              className={
                "nav-item" +
                (window.location.pathname.includes("/technology")
                  ? " current-nav-item"
                  : "")
              }
            >
              <a
                title="Technology"
                href={process.env.PUBLIC_URL + "/technology"}
              >
                Technology
              </a>
            </li>

            {/* <li className={'nav-item' + (window.location.pathname.includes( '/applications' ) || window.location.pathname.includes( "/service-inside" ) ? ' current-nav-item' : '')}>
                    <a title="Applications" href={process.env.PUBLIC_URL + '/applications'}>Applications</a>
                </li> */}

            <li
              className={
                "nav-item" +
                (window.location.pathname.includes("/our-company")
                  ? " current-nav-item"
                  : "")
              }
            >
              <a
                title="Our company"
                href={process.env.PUBLIC_URL + "/our-company"}
              >
                Our company
              </a>
            </li>

            <li
              className={
                "nav-item" +
                (window.location.pathname.includes("/contacts")
                  ? " current-nav-item"
                  : "")
              }
            >
              <a title="Contact Us" href={process.env.PUBLIC_URL + "/contacts"}>
                Contacts
              </a>
            </li>
          </ul>
        </div>

        {/* Here is saivvy's logo and copyright information */}
        <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row align-items-center justify-content-between">
          <div
            className="logo logo-primary"
            style={{ display: path === "/404" ? "none" : "block" }}
          >
            <img src="assets/img/logo/logo-light.svg" alt="Logo" />
          </div>

          {
            <div className="footer">
              <div className="align-self-center">
                <div className="copyright">
                  <p>
                    © {new Date().getFullYear()} Saivvy. All Rights Reserved.{" "}
                  </p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </footer>
  );
};

export default Footer;
