import React from 'react';

import CategoriesData from '../../data/sidebar/categoriesData.json';

const  Categories = () => {
    return (
        <div className="widget widget_categories">
            <h5 className="widget-title">Categories</h5>

            <ul>
                { CategoriesData && CategoriesData.map( (item, key) => {
                    return (
                        <li key={key} className="cat-item">
                            <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>{ item.title }</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default Categories;
