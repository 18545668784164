import React from 'react';
import { Link } from 'react-scroll/modules';

import CommentForm from '../../components/form/CommentForm';

const Comments = () => {
    return (
        <div id="comments" className="comments-area spacer m-top-xl">
            <div className="comment-title">
                <h2>1 Comment</h2>
            </div>

            <ul className="comment-list list-unstyled style-default">
                <li className="comment">
                    <div className="comment-wrapper">
                        <div className="comment-details">
                            <div className="comment-content">
                                <div className="comment-content-left">
                                    <div className="comment-img">
                                        <img src="assets/img/placeholder/18_img.png" alt="Rosie Ford" />
                                    </div>
                                </div>

                                <div className="comment-content-right">
                                    <h5 className="comment-author after">
                                        <a title="Rosie Ford" href={ process.env.PUBLIC_URL +  'news-single-post' }>Rosie Ford</a>
                                    </h5>

                                    <div className="comment-description">
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
                                    </div>

                                    <div className="comment-reply no-space">
                                        <Link to="reply-title" spy={true} smooth={true} duration={0} title="Reply" className="btn btn-lg btn-link link-color-2 p-0 border-0 min-w-auto link-no-space scroll-to-id" href="#reply-title">
                                            <i className="fas fa-reply"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div className="block spacer m-top-xl">
                <div id="respond" className="comment-respond">
                    <h2 id="reply-title" className="comment-reply-title">Leave a Reply <a rel="nofollow" id="cancel-comment-reply-link" className="btn btn-link link-color-2 border-0 p-0 min-w-auto link-no-space" href={ process.env.PUBLIC_URL + '/news-single-post' } style={{ display: 'none' }}>Cancel Reply</a></h2>

                    <CommentForm />
                </div>
            </div>
        </div>
    );
};

export default Comments;
