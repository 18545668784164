import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import HeaderLogo from '../../blocks/logo/HeaderLogo';
import ModalMenuPrimary from '../../blocks/menu/ModalMenuPrimary';
import ModalMenuSecondary from '../../blocks/menu/ModalMenuSecondary';

class MenuModal extends Component {
    constructor(context) {
        super(context);

        this.state = {
            showModal: false,
        };

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    };

    open() {
        this.setState({ showModal: true });
    };

    close() {
        this.setState({ showModal: false });
    };

    render() {
        return (
            <Fragment>
                <div className="menu-toggle">
                    <button onClick={ this.open } type="button" className="btn btn-link border-0 p-0 min-w-auto" data-toggle="modal" data-target="#menu-modal"><i className="forch-icon-menu"></i></button>
                </div>

                <Modal
                    className="modal fade"
                    id="menu-modal"
                    tabIndex="-1"
                    show={ this.state.showModal }
                    onHide={ this.close }
                    backdrop={ false }
                    aria-labelledby="menu-modal"
                    aria-hidden="true"
                    dialogClassName="modal-full"
                    ref={ (node) => (this.chart = node) }
                >
                    <div className="wrapper">
                        <div className="modal-content">
                            <div className="modal-header modal-header-top">
                                <div className="header-content d-flex justify-content-between w-100">
                                    <div className="header-left align-self-center">
                                        <HeaderLogo logoColor="light" />
                                    </div>

                                    <div className="header-right d-flex justify-content-end">
                                        <div className="d-flex align-items-center">
                                            <div className="menu-close-toggle">
                                                <button onClick={this.close} type="button" className="close btn btn-lg btn-link border-0 p-0 min-w-auto" data-dismiss="modal" aria-label="Close"><i className="forch-icon-close"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-body modal-body-centered">
                                {/* <SearchModal /> */}

                                <div className="animated fadeinright d-flex justify-content-end w-100">
                                    <ModalMenuPrimary />
                                </div>
                            </div>

                            <div className="modal-footer">
                                <ModalMenuSecondary />
                            </div>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    };
};

export default MenuModal;
