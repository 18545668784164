import React from 'react';

import FactsData from '../../data/home/factsData.json';

const Facts = () => {
    return (
        <section id="facts" className="block">
            <div className="bg-quaternary text-white  spacer p-top-xl p-bottom-xl overflow-hidden">
                <div className="wrapper">
                    <div className="title red">
                        <h4>Why we're here</h4>
                    </div>

                    <div className="row gutter-width-md facts-items">
                        { FactsData && FactsData.map( (item, key) => {
                            return (
                                <div key={key} className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                    <div className="facts-item">
                                        <div className="facts-item-content">
                                            {/* <div className="facts-item-left">
                                                <div className="facts-item-text">
                                                    <p>{ new Date().getFullYear() }</p>
                                                </div>
                                            </div> */}
        
                                            <div className="facts-item-right">
                                                <h2 className="facts-item-title">{ item.count }</h2>
        
                                                <p className="facts-item-text-1">{ item.description }</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Facts;
