import React, { useEffect } from 'react';
import Swiper from 'swiper';

import ApplicationsData from '../../data/home/applicationsData.json';

const Applications = () => {
    useEffect(() => {
        new Swiper( '.adv-swiper-container', {
            // ADV swiper
            noSwipingClass: 'adv-swiper-no-swiping',
            containerModifierClass: 'adv-swiper-container-',
            slideClass: 'adv-swiper-slide',
            slideBlankClass: 'adv-swiper-slide-invisible-blank',
            slideActiveClass: 'adv-swiper-slide-active',
            slideDuplicateActiveClass: 'adv-swiper-slide-duplicate-active',
            slideVisibleClass: 'adv-swiper-slide-visible',
            slideDuplicateClass: 'adv-swiper-slide-duplicate',
            slideNextClass: 'adv-swiper-slide-next',
            slideDuplicateNextClass: 'adv-swiper-slide-duplicate-next',
            slidePrevClass: 'adv-swiper-slide-prev',
            slideDuplicatePrevClass: 'adv-swiper-slide-duplicate-prev',
            wrapperClass: 'adv-swiper-wrapper',
            observer: true,
            observeParents: true,
            parallax:true,
            navigation: {
                disabledClass: 'adv-swiper-button-disabled',
                hiddenClass: 'adv-swiper-button-hidden',
                lockClass: 'adv-swiper-button-lock',
                nextEl: '.adv-3-swiper-button-next',
                prevEl: '.adv-3-swiper-button-prev'
            },
            spaceBetween: 30,
            slidesPerView: 1,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            }
        } );
    });

    return (
        <div className="text-black col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <div className="adv-slider-applications">
                <div className="adv-swiper-container">
                    <div className="adv-swiper-wrapper applications-items">
                        { ApplicationsData && ApplicationsData.map( (item, key) => {
                            return ( 
                                <div key={key} className="adv-swiper-slide applications-item">
                                    <div className="applications-item-content">

                                        <div className="applications-item-t-head">
                                            <a href={ process.env.PUBLIC_URL + item.link }>
                                                
                                                <h5>{ item.title }</h5>
                                            </a>
                                        </div>

                                        <div className="applications-item-body text-quinary">
                                            <p>{ item.description }</p>
                                        </div>

                                        {/* <div className="service-item-footer"> */}
                                            <a className="btn btn-link p-0 border-0 min-w-auto" href={ process.env.PUBLIC_URL + item.link }>
                                                <i className="forch-icon-arrow-right body_links_hover_color"></i>
                                            </a>
                                        {/* </div> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="adv-swiper-control text-quinary">
                        <div className="adv-3-swiper-button-prev text-quinary">Previous</div>

                        <div className="adv-3-swiper-button-next text-quinary">Next</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Applications;
