import React from 'react';

const PageTitle = () => {
    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="row gutter-width-lg">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div className="title">
                            <h1 className="large">Industrial equipment</h1>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-lg with-pb-lg spacer p-top-lg justify-content-end">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div className="description">
                            <p>The term covers a wide range of work from large ships and bridges to precise engine parts and delicate jewelry. It therefore includes a correspondingly wide range of skills, processes, and tools.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitle;
