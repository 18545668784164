import React, { useEffect } from 'react';

// import { Link } from 'react-scroll';
import Swiper from 'swiper';

const ApplicationsSwiper = () => {

  useEffect(() => {
    // Swiper Configuration
      new Swiper(".swiper-container", {
        slidesPerView: 1.5,
        spaceBetween: 320,
        breakpoints: {
          500: {
            slidesPerView: 1.5,
            spaceBetween: 160,
          },
          700: {
            slidesPerView: 1.5,
            spaceBetween: 320,
          }
        },
      
        centeredSlides: true,
        freeMode: false,
        grabCursor: true,
        // loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        observer: true,
        observeParents: true,
        parallax:true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });

    });

    return (
        <div class="swiper">  
          
          <div className="wrapper">
            
          <h2>Applications</h2>
          <p>Get ready to tackle any terrain with our autonomous driving software. 
              Whether you're navigating farm trails or muddy fields, our advanced 
              home has got you covered. Our software is built to handle a wide range 
              of off-road applications, making it the ultimate tool for conquering the 
              great outdoors.</p>
              </div>

            <div class="swiper-container">
            <div class="swiper-wrapper">
            <div class="swiper-slide">
                <div class="card-image">
                    <img src="../../assets/img/pages/home/applications/agriculture-vehicles.jpg" alt="Image Slider"/>
                    <div class="bottom-left">Agriculture machines</div>
                </div>
                </div>
                <div class="swiper-slide">
                <div class="card-image">
                    <img src="../../assets/img/pages/home/applications/cargo-trucks.jpg" alt="Image Slider"/>
                    <div class="bottom-left">Cargo trucks</div>
                </div>
                </div>
                <div class="swiper-slide">
                <div class="card-image">
                    <img src="../../assets/img/pages/home/applications/earth-moving-equipment.jpg" alt="Image Slider"/>
                    <div class="bottom-left">Earth moving equipment</div>
            
                </div>
                </div>
                <div class="swiper-slide">
                <div class="card-image">
                    <img src="../../assets/img/pages/home/applications/forestry-vehicles.jpg" alt="Image Slider"/>
                    <div class="bottom-left">Forestry vehicles</div>
                </div>
                </div>
                <div class="swiper-slide">
                <div class="card-image">
                    <img src="../../assets/img/pages/home/applications/earth-transport.jpg" alt="Image Slider"/>
                    <div class="bottom-left">Caterpillars</div>
                </div>
                </div>
                {/* <div class="swiper-slide">
                <div class="card-image">
                    <img src="../../assets/img/pages/home/applications/mines.jpg" alt="Image Slider"/>
                </div>
                </div> */}
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next">
                <i class="fas fa-chevron-circle-right arrow-icon-right"></i>
            </div>
            <div class="swiper-button-prev">
                <i class="fas fa-chevron-circle-left arrow-icon-left"></i>
            </div>
        </div>
    </div>
    );
};




export default ApplicationsSwiper;