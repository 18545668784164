import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Subscribe from "../blocks/home/Subscribe";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitle from "../blocks/technology/PageTitle";

import Partners from "../blocks/home/TechnologyApplications";
import Facts from "../blocks/home/Facts";

import sample from "../assets/img/video/technology-top.mp4";
import simulatorImg from "../assets/img/pages/technology/sim3.png";
import sensorsImg from "../assets/img/pages/technology/sensors_edited.png";
import bevImg from "../assets/img/pages/technology/bev1.png";
import planningImg from "../assets/img/pages/technology/planning.png";
import controlImg from "../assets/img/pages/technology/control.png";
import loopImg from "../assets/img/pages/technology/improvement_loop_bg.png";

const Technology = () => {
  useEffect(() => {
    document.body.classList.add("page");
    document.body.classList.add("bg-fixed");
    document.body.classList.add("bg-stripes");

    return () => {
      document.body.classList.remove("page");
      document.body.classList.remove("bg-fixed");
      document.body.classList.remove("bg-stripes");
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Technology | Saivvy - Off-Road Autonomous Driving</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Header />

      <main id="main" className="site-main">
        <div className="technology-video">
          <div className="video-text">
            <div id="text" className="block spacer">
              <div className="wrapper">
                <h1 className="large">Technology</h1>
              </div>
            </div>
          </div>

          <video className="videoTag" autoPlay loop muted>
            <source src={sample} type="video/mp4" />
          </video>
        </div>

        <div id="about-us" className="block">
          <div className="technology-top">
            <div id="img" className="block spacer">
              <div id="text" className="block spacer">
                <div className="wrapper">
                  <h2>
                    An autonomous driving software built for whatever you call a
                    road.
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="technology-details">
            <div id="img" className="block spacer">
              <div id="text" className="block spacer">
                <div className="wrapper">
                  <h2>
                    An autonomous driving software built for whatever you call a
                    road.
                  </h2>
                </div>
              </div>
            </div>
          </div>


        {/* WIP */}

          <div className="technology-middle">
                <article>
                  <div className="re-order">
                      <div class="box col-50 text">
                          <div className='wrapper'>
                              <h3>Simulation</h3>
                              <p>Gathering data from a driving simulator is an important
                                step in the development of autonomous driving technology.
                                By simulating various driving scenarios and conditions, we
                                can gather a large and diverse set of data that allows our
                                AI model to learn and make accurate decisions in a wide
                                range of environments. By using a driving simulator, we
                                are able to efficiently and safely gather the data needed
                                to train and fine-tune our AI model, ultimately resulting
                                in a more reliable and trustworthy autonomous driving
                                system.
                              </p>
                          </div>
                      </div>
                      <div class="box col-50 image">
                          <img src={simulatorImg} />
                      </div>
                    </div>
                </article>
                <article>
                    <div className="re-order">
                        <div class="box col-50 text">
                            <div className='wrapper'>
                                <h3>Sensors</h3>
                                <p>
                                The perception component in the self-driving pipeline is
                                responsible for interpreting the data collected by sensors
                                on the autonomous vehicle. This includes identifying and
                                classifying objects in the environment, such as other
                                vehicles, pedestrians, and traffic signals. The output of
                                the perception component is used by the decision-making
                                component to determine the appropriate action for the
                                vehicle to take, such as changing lanes or braking.
                              </p>
                            </div>
                        </div>
                        <div class="box col-50 image">
                            <img src={sensorsImg}/>
                        </div>
                    </div>
                </article>
                <article>
                  <div className="re-order">
                      <div class="box col-50 text">
                          <div className='wrapper'>
                              <h3>Perception</h3>
                              <p>
                              The perception component in the self-driving pipeline is
                                responsible for interpreting the data collected by sensors
                                on the autonomous vehicle. This includes identifying and
                                classifying objects in the environment, such as other
                                vehicles, pedestrians, and traffic signals. The output of
                                the perception component is used by the decision-making
                                component to determine the appropriate action for the
                                vehicle to take, such as changing lanes or braking.
                            </p>
                          </div>
                      </div>
                      <div class="box col-50 image">
                          <img src={bevImg} />
                      </div>
                    </div>
                </article>
                <article>
                    <div className="re-order">
                        <div class="box col-50 text">
                            <div className='wrapper'>
                                <h3>Planning</h3>
                                <p>
                                Our company is renowned for our expertise in designing,
                                training, and testing the latest generation of artificial
                                intelligence (AI) approaches. We have a team of highly
                                skilled and experienced AI engineers who are dedicated to
                                pushing the boundaries of what is possible with this
                                technology. We are constantly experimenting with new
                                algorithms and architectures to find the most effective
                                solutions to the challenges our clients face. Our rigorous
                                testing process ensures that our AI models are able to
                                perform at the highest levels, providing reliable and
                                accurate results. Our commitment to innovation and
                                excellence has helped us to become leaders in the field of
                                AI, and we are constantly striving to improve and advance
                                this technology.
                              </p>
                            </div>
                        </div>
                        <div class="box col-50 image">
                            <img src={planningImg}/>
                        </div>
                    </div>
                </article>
                <article>
                  <div className="re-order">
                      <div class="box col-50 text">
                          <div className='wrapper'>
                              <h3>Control</h3>
                              <p>
                              Gathering data from a driving simulator is an important
                              step in the development of autonomous driving technology.
                              By simulating various driving scenarios and conditions, we
                              can gather a large and diverse set of data that allows our
                              AI model to learn and make accurate decisions in a wide
                              range of environments. By using a driving simulator, we
                              are able to efficiently and safely gather the data needed
                              to train and fine-tune our AI model, ultimately resulting
                              in a more reliable and trustworthy autonomous driving
                              system.
                            </p>
                          </div>
                      </div>
                      <div class="box col-50 image">
                          <img src={controlImg} />
                      </div>
                    </div>
                </article>
                <article>
                    <div className="re-order">
                        <div class="box col-50 text">
                            <div className='wrapper'>
                                <h3>Continuous Improvement</h3>
                                <p>
                                Our company is renowned for our expertise in designing,
                                training, and testing the latest generation of artificial
                                intelligence (AI) approaches. We have a team of highly
                                skilled and experienced AI engineers who are dedicated to
                                pushing the boundaries of what is possible with this
                                technology. We are constantly experimenting with new
                                algorithms and architectures to find the most effective
                                solutions to the challenges our clients face. Our rigorous
                                testing process ensures that our AI models are able to
                                perform at the highest levels, providing reliable and
                                accurate results. Our commitment to innovation and
                                excellence has helped us to become leaders in the field of
                                AI, and we are constantly striving to improve and advance
                                this technology.
                              </p>
                            </div>
                        </div>
                        <div class="box col-50 image">
                            <img src={loopImg}/>
                        </div>
                    </div>
                </article>
            </div>

          {/* WIP */}

          <Facts />
        </div>
      </main>

      <Subscribe />
      <Footer />
    </Fragment>
  );
};

export default Technology;
