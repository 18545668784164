import React from 'react';
import ContactsForm from '../../components/form/ContactsForm';

const Contacts = () => {
    return (

            <div className="contacts-top">
                        <div className="wrapper">
                            <div className="spacer">

                                <div className="contact-us-title">
                                    <h1>Contact us</h1>
                                </div>
                                
                                <ContactsForm />
                            </div>        
                        </div>
            </div>
    );
};

export default Contacts;
