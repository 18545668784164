import React from 'react';
import { Link } from 'react-scroll';

const LoadMore = () => {
    return (
        <div className="loadmore spacer m-top-lg">
            <Link  
                to="header"
                id="loadmore"
                data-type="blog"
                spy={true}
                smooth={true}
                duration={0}
                title="Load More" 
                className="btn btn-primary" 
                href="#">
                    Load More
            </Link>
        </div>
    );
};

export default LoadMore;
