import React from 'react';

import TagsData from '../../data/sidebar/tagsData.json';

const Tags = () => {
    return (
        <div className="widget widget_tag_cloud">
            <h5 className="widget-title">Tags</h5>

            <div className="tagcloud">
                { TagsData && TagsData.map( (item, key) => {
                    return (
                        <a key={key} title={ item.title } href={ process.env.PUBLIC_URL + item.link } className="tag-cloud-link">{ item.title }</a>
                    )
                })}
            </div>
        </div>
    );
};

export default Tags;
