import React, { useEffect, useState } from 'react';

const HeaderLogo = ({logoColor}) => {
    const [color, setColor] = useState('dark');

    useEffect(() => {
        if (logoColor) {
            setColor(logoColor);
        }
    },[logoColor]);

    return (
        <div className="header-logo align-self-center">
            <a className={'logo logo-' + ( color === 'dark' ? 'primary' : 'secondary' )} title="Logo" href={ process.env.PUBLIC_URL + '/' }>
                <img src={'assets/img/logo/logo-' + color + '.svg'} alt="Logo" />
            </a>
        </div>
    );
};

export default HeaderLogo;
