import React, { useEffect } from 'react';
import ApplicationsData from '../../data/home/technologyApplicationsData.json';

function Applications() {
    useEffect(() => {
        let technology_applications = document.querySelector('#technologyApplications .technologyApplications .technologyApplications-items');
        technology_applications.lastElementChild.classList.add('bg-primary');
    },[]);

    return (
        <section id="technologyApplications" className="p-top-xl p-bottom-xl">
            {/* <div className="wrapper"> */}

            


                <div className="technologyApplications">
                <div className="wrapper">
                            <h2>Applications</h2>
                            <h4>Get ready to tackle any terrain with our autonomous driving software. 
                                Whether you're navigating farm trails or muddy fields, our advanced 
                                technology has got you covered. Our software is built to handle a wide range 
                                of off-road applications, making it the ultimate tool for conquering the 
                                great outdoors.</h4>
                                
                        {/* </div> */}

                    
                    <div className="technologyApplications-items">
                        { ApplicationsData && ApplicationsData.map( (item, key) => {
                            return (
                                <div key={key} className="technologyApplications-item">
                                    <div className="technologyApplications-item-content">
                                        <img src={ item.imgSrc } alt={ item.title } />
                                        <div className="img-caption">
                                        <h5>{ item.title }</h5> </div>
                                    </div>

                                    
                                    
                                </div>
                            )
                            
                        })}
                    </div>
                    
                </div>
            </div>
        </section>
    );
};

export default Applications;
