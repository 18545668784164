import React, { useEffect } from 'react';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapBoxGeo from './mapBoxGeo.json';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWR2ZWl0cyIsImEiOiJja3FjNWRkaTkweGcxMnNxdGgzd2tkcDZnIn0.1pulnkWs-9uN43NKgVe9dw';

const Map = () => {
    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiYWR2ZWl0cyIsImEiOiJja3FjNWRkaTkweGcxMnNxdGgzd2tkcDZnIn0.1pulnkWs-9uN43NKgVe9dw';

        let mapBoxGeoJson = MapBoxGeo;

        let mapBox = new mapboxgl.Map( {
            container: 'mapbox',
            style: 'mapbox://styles/adveits/ckqbb3vwg31dl18n3bxmgtwut',
            center: mapBoxGeoJson[0]['coordinates'],
            zoom: 4
        } );

        mapBox.scrollZoom.disable();
        mapBox.addControl( new mapboxgl.NavigationControl() );

        mapBox.on( 'load', function() {
            mapBoxGeoJson.forEach( function( marker, index ) {
                let el       = document.createElement( 'div' );
                el.className = 'adv-marker';

                if ( marker.style === 1 ) {
                    el.innerHTML = '<div class="adv-marker-content style-1">' + marker.title + '</div>';
                } else {
                    el.innerHTML = '<div class="adv-marker-content style-2">' + marker.title + '</div>';
                }

                let mapBoxPopup = new mapboxgl.Popup( { offset: 25 } ).setHTML(
                    '<p class="mb-0 after">' + marker.description + '</p>'
                );

                new mapboxgl.Marker( el, { anchor: 'bottom' } ).setLngLat( marker.coordinates ).setPopup( mapBoxPopup ).addTo( mapBox );
            } );
        } );
    })

    return (
        <div id="map" className="block">
            <div className="map">
                <div className="img object-fit">
                    <div className="object-fit-cover">
                        <div id="mapbox" ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Map;
