import React from 'react';

const Meta = () => {
    return (
        <div className="meta">
            <p>
                <span><a href={ process.env.PUBLIC_URL + '/news' }>By admin</a></span>
                <span>-</span>
                <span>Comments (1)</span>
                <span>-</span>
                <span className="category"><a title="Welding" href={ process.env.PUBLIC_URL + '/news' }>Welding</a></span>
            </p>
        </div>
    );
};

export default Meta;
