import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Subscribe from '../blocks/home/Subscribe';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/applications/PageTitle';
import ApplicationsBlock from '../blocks/home/Applications';
import Projects from '../blocks/home/Projects';
import Partners from '../blocks/home/TechnologyApplications';

const Applications = () => {
    useEffect(() => {
        document.body.classList.add('page');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('page');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    },[]);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Applications | Saivvy - Off-Road Autonomous Driving</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Header />

            <main id="main" className="site-main">
                <PageTitle />
                

                <div id="page-content" className="spacer p-top-xl">
                    
                    <div className="content ">
                        <div className="row gutter-width-lg with-pb-lg spacer p-top-lg p-bottom-xl">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                            </div>
                            <div className="col-xl-4 p-bottom-xl">
                                <div className="description p-bottom-xl text-seven">
                                    <h3>Saivvy driving</h3>
                                </div>
                                <div className="description p-top-md p-bottom-xl text-black">
                                    <h3>Welcome to multipurpose off-road autonomous driving</h3>
                                </div>
                                <div className="description p-top-md p-bottom-xl text-quinary">
                                    <p>We are building the next generation off-road autonomous software, 
                                        which adapts to any domains without the need for re-training. </p>
                                </div>
                            </div>
                        </div>
                        <div id="applications" className="block">
                            <div className="bg-seven text-white spacer p-top-xl p-bottom-xl">
                                <div className="wrapper">
                                    <div className="row gutter-width-md with-pb-lg">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                            <div className="title">
                                                <h2>We offer</h2>
                                            </div>

                                            <div className="description text-quinary">
                                                <p>We are developing the most advanced off-road autonomous driving system.
                                                    Our mission is to make it safe and easy for vehicles to traverse roadless environments autonomously. </p>
                                                    
                                            </div>
                                        </div>

                                        <ApplicationsBlock />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <Partners /> */}

                        {/* <Projects /> */}

                    </div>
                </div>
            </main>

            <Subscribe />
            <Footer />
            
        </Fragment>
    );
};

export default Applications;
