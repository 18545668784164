import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/application-inside/PageTitle';
import Contacts from '../blocks/home/Contacts';

const ApplicationInside = () => {
    useEffect(() => {
        document.body.classList.add('single');
        document.body.classList.add('single-adveits_applications');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('single');
            document.body.classList.remove('single-adveits_applications');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    },[]);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Application inside | Saivvy - Off-Road Autonomous Driving</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <div className="content">
                    <div className="clearfix">
                        <PageTitle />

                        <div id="img" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="row gutter-width-md with-pb-lg">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="img-size-2">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/11_img.jpg" alt="Industrial equipment" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="img-size-2">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/12_img.jpg" alt="Industrial equipment" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="text" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="row gutter-width-md with-pb-lg">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="description">
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="description">
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="text-2" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="row gutter-width-lg">
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <div className="description">
                                            <h2>When it comes to steel work and Engineering there is simply no one better</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row gutter-width-lg with-pb-lg spacer p-top-lg justify-content-end">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <div className="description max-width-1 text-secondary">
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section id="contacts" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="title">
                                    <h2>Contacts</h2>
                                </div>

                                <Contacts />
                            </div>
                        </section>
                    </div>
                </div>
            </main>

            <Footer />
            
        </Fragment>
    );
};

export default ApplicationInside;
