import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/news/PageTitle';
import NewsBlog from '../blocks/news/NewsBlog';
import Sidebar from '../blocks/news/Sidebar';

const News = () => {
    useEffect(() => {
        document.body.classList.add('blog');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('blog');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    },[]);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>News | Saivvy - Off-Road Autonomous Driving</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitle />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="blog">
                                <div className="row gutter-width-md">
                                    <NewsBlog />

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                       <Sidebar />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
            
        </Fragment>
    );
};

export default News;
