import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitle from '../blocks/project-inside/PageTitle';
import Contacts from '../blocks/home/Contacts';

const ProjectInside = () => {
    useEffect(() => {
        document.body.classList.add('single');
        document.body.classList.add('single-post');
        document.body.classList.add('bg-fixed');
        document.body.classList.add('bg-stripes');

        return () => {
            document.body.classList.remove('single');
            document.body.classList.remove('single-post');
            document.body.classList.remove('bg-fixed');
            document.body.classList.remove('bg-stripes');
        }
    },[]);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Project inside | Saivvy - Off-Road Autonomous Driving</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitle />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="content">
                        <div id="img" className="block">
                            <div className="img-size-1">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/20_img.jpg" alt="Tools" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section id="about-the-project" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="title">
                                    <h2>About the project</h2>
                                </div>

                                <div className="row gutter-width-md with-pb-md">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div className="description text-secondary">
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="description">
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div id="img-2" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="row gutter-width-md with-pb-lg">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="img-size-2">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/11_img.jpg" alt="Industrial equipment" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="img-size-2">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/12_img.jpg" alt="Industrial equipment" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="text" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="row gutter-width-md with-pb-lg">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="description">
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="description">
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section id="contacts" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="title">
                                    <h2>Contacts</h2>
                                </div>

                                <Contacts />
                            </div>
                        </section>
                    </div>
                </div>
            </main>

            <Footer />
            
        </Fragment>
    );
};

export default ProjectInside;
